<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">REGISTER EXPENSES</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-select
                dense
                v-model="month_of"
                :items="month_of_items"
                item-value="month_of"
                item-text="month_of"
                label="Month Of"
                required
                outlined
              ></v-select>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-select
                v-model="particulars"
                :items="particulars_items"
                item-value="particulars"
                item-text="particulars"
                label="Particulars"
                required
                dense
                outlined
              ></v-select>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="amount"
                label="Amount"
                dense
                outlined
                type="number"
                :min="0"
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_bank_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',

        month_of_items: [],
        month_of: '',
        amount: 0,
        particulars: '',
        particulars_items: [],
      }
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.retrieve_transaction_month(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.get_expenses_particulars()
        .then(response => {
          this.particulars_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id']),
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('expenses_summary', ['get_expenses_particulars', 'register_expenses']),
      save_bank_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (this.branch_id == 1) {
            this.alert = true
            this.alert_message = 'Please select Branch First'
            this.saving = false
          } else {
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('month_of', this.month_of);
            data.append('particulars', this.particulars);
            data.append('amount', this.amount);
            this.register_expenses(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
